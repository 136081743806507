import React from 'react';
import { createUseStyles } from 'react-jss';
import { spacing, breakpoint, pxToRem } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Button from 'src/components/core/Button';
import Typography from 'src/components/core/Typography';
import { assetLink } from 'src/utils';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: spacing(10),
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
  secondaryText: {
    margin: spacing(8),
  },
  icon: {
    alignSelf: 'center',
    height: pxToRem(120),
  },
});

const GetStarted: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer
      className={classes.container}
      backgroundShape={4}
      background="light-blue"
      backgroundShapeBlendMode="soft-light"
    >
      <img src={assetLink('landing-ui/icons/EmailAppIcon.svg')} alt="Email app icon" className={classes.icon} />
      <Typography variant="p1" color="blackish" centered className={classes.secondaryText}>
        Email is a great start. Check out our other apps to take your business to the next level.
      </Typography>
      <Typography variant="h2" color="blue" underline centered>
        Powerful Tools To Enhance Your Business
      </Typography>
      <Button
        variant="filled"
        color="teal"
        href="/register/"
        className={classes.button}
      >
        Get started
      </Button>
    </SectionContainer>
  );
};

export default GetStarted;
