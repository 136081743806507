import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  spacing, breakpoint, pxToRem, theme,
} from 'src/theme';
import { assetLink } from 'src/utils';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  grid: {
    display: 'grid',
    justifyContent: 'center',
    columnGap: spacing(10),
    rowGap: spacing(10),
    margin: spacing(10),
    gridTemplateColumns: `repeat(3, minmax(0, ${pxToRem(360)}))`,
    [breakpoint.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      marginTop: spacing(10),
    },
  },
  underline: {
    marginBottom: spacing(10),
    [breakpoint.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  divider: {
    marginLeft: '15%',
    marginRight: '15%',
    color: theme.palette.secondary.BLUE_3,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 25%)',
    padding: spacing(8),
    borderRadius: 10,
    backgroundColor: theme.palette.primary.WHITE,
  },
  iconContainer: {
    width: pxToRem(100),
    height: pxToRem(100),
    borderRadius: pxToRem(45),
    backgroundColor: theme.palette.secondary.LIGHT_BLUE,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: pxToRem(35),
  },
});

const content: {
  iconSrc: string,
  label: string,
  details: string,
}[] = [
  {
    iconSrc: assetLink('landing-ui/icons/EmailGoogleIcon.svg'),
    label: 'Backed by Google',
    details: 'Powered by Google Workspace to provide fast and reliable email management.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/EmailTrackingIcon.svg'),
    label: 'Message Tracking',
    details: 'See who viewed and clicked links on your email.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/EmailTemplatesIcon.svg'),
    label: 'Templates & Shortcuts',
    details: 'Create templates to quickly respond to incoming mail.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/EmailSecurityIcon.svg'),
    label: 'Compehensive Security',
    details: 'Be protected against hackers, spam and malware protection with Google\'s world class security.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/EmailSyncIcon.svg'),
    label: 'Integrations',
    details: 'Automatically integrates with your all your other Bizwise apps seamlessly.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/EmailMobileIcon.svg'),
    label: 'Mobile App Compatibility',
    details: 'Integrates with any email app on your mobile device.',
  },
];

const EmailFeatures: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <hr className={classes.divider} />
      <SectionContainer
        backgroundShape={4}
        backgroundShapeBlendMode="soft-light"
        className={classes.container}
      >
        <Typography
          variant="h2"
          color="blue"
          underline
          centered
          underlineClassName={classes.underline}
        >
          Serious Power For Serious Business
        </Typography>
        <div className={classes.grid}>
          {content.map(({ iconSrc, label, details }) => (
            <div className={classes.card} key={label}>
              <div className={classes.iconContainer}>
                <img src={iconSrc} alt={`${label} Icon`} className={classes.icon} />
              </div>
              <Typography variant="h3" color="blue" centered style={{ marginTop: spacing(6) }}>
                {label}
              </Typography>
              <Typography variant="p2" color="textSecondary" centered style={{ marginTop: spacing(2) }}>
                {details}
              </Typography>
            </div>
          ))}
        </div>
      </SectionContainer>
    </>
  );
};

export default EmailFeatures;
