import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import HeroSection from 'src/sections/EmailModule/Hero';
import DetailsSection from 'src/sections/EmailModule/Details';
import FaqSection, { FaqTypes } from 'src/sections/shared/Faq';
import ApiWrapper from 'src/components/ApiWrapper';
import AppScreenshotsSection from 'src/sections/shared/AppScreenshots';
import { EmailImages } from 'src/sections/shared/StaticImages';
import EmailFeatures from 'src/sections/EmailModule/Features';
import EmailPricing from 'src/sections/EmailModule/Pricing';
import GetStarted from 'src/sections/EmailModule/GetStarted';

const EmailProductPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo title="The Email App" />
      <HeroSection />
      <DetailsSection />
      <AppScreenshotsSection
        title="An Email App That You'll Love To Use"
        description="No more clutter. No more ads. Powerful features to take your email to the next level."
        screenshots={[
          EmailImages[0],
          EmailImages[1],
          EmailImages[2],
          EmailImages[3],
          EmailImages[4],
        ]}
      />
      <EmailFeatures />
      <EmailPricing />
      <FaqSection types={[FaqTypes.EMAIL_MODULE]} />
      <GetStarted />
    </Layout>
  </ApiWrapper>
);

export default EmailProductPage;
